
import { AmenityType } from '@/models/dto/Amenity'
import { updateTripAmenities } from '@/services/trips'
import { quoteFormAmenities } from '@/utils/amenity'
import { deepClone } from '@/utils/deepClone'
import { guaranteedAmenityTypeIds, bestEffortAmenityTypeIds, AmenityTypeId, paidAmenityTypeIds } from '@/utils/enum'
import { EventBus } from '@/utils/event-bus'
import { Component, Prop, Vue } from 'vue-property-decorator'

const NOTES_ENABLED_AMENITY_TYPES = [
  AmenityTypeId.ADA,
  AmenityTypeId.NineteenACertification,
  AmenityTypeId.BottledWater,
  AmenityTypeId.AlcoholConsumption,
  AmenityTypeId.MakeModelYear,
  AmenityTypeId.BusColor,
  AmenityTypeId.BusLogos,
  AmenityTypeId.NonStandard,
  AmenityTypeId.TripCoordinators,
  AmenityTypeId.BusWraps
]

@Component({})
export default class ReservationAmenitiesSidebar extends Vue {
  @Prop({ type: Number, required: true }) readonly tripId: number
  @Prop({ type: Object, required: true }) readonly tripAmenities: AmenityType[]
  @Prop({ type: String, default: '' }) readonly bookedByName: string

  paidAmenitiesList: AmenityType[] = []
  guaranteedAmenitiesList = []
  bestEffortAmenitiesList = []
  selectedAmenities: AmenityType[] = []

  get filteredAmenities(): AmenityType[] {
    return this.tripAmenities
      .filter(amenity => ![AmenityTypeId.Bathroom, AmenityTypeId.Luggage, AmenityTypeId.Outlets].includes(amenity.id))
  }

  mounted(): void {
    if (this.bookedByName === 'Sales Bot') {
      this.paidAmenitiesList = quoteFormAmenities
        .filter(amenity => Object.values(paidAmenityTypeIds).includes(amenity.id))
    }

    this.guaranteedAmenitiesList = quoteFormAmenities
      .filter(amenity => Object.values(guaranteedAmenityTypeIds).includes(amenity.id))
      .filter(amenity => !this.paidAmenitiesList.some(a => a.id === amenity.id))

    this.bestEffortAmenitiesList = quoteFormAmenities
      .filter(amenity => Object.values(bestEffortAmenityTypeIds).includes(amenity.id))

    this.selectedAmenities = deepClone(this.tripAmenities)
  }

  isSelected(amenity): boolean {
    return this.selectedAmenities.some(a => a.id === amenity.id)
  }

  hasNotes(amenity): boolean {
    return NOTES_ENABLED_AMENITY_TYPES.includes(amenity.id)
  }

  selectAmenity(amenity, selected): void {
    if (selected) {
      this.selectedAmenities.push(amenity)
    } else {
      const index = this.selectedAmenities.findIndex(a => a.id === amenity.id)
      if (index >= 0) {
        this.selectedAmenities.splice(index, 1)
      }
    }
  }

  selectedAmenityNote(amenity): string {
    return this.selectedAmenities.find(a => a.id === amenity.id)?.note?.note
  }

  updateAmenityNote(amenity, val): void {
    const selectedAmenity = this.selectedAmenities.find(a => a.id === amenity.id)
    if (selectedAmenity) {
      if (!selectedAmenity.note) {
        selectedAmenity.note = {
          note: val
        }
      } else {
        selectedAmenity.note.note = val
      }
    }
  }

  async saveAmenities(): Promise<void> {
    const payload = {
      tripAmenities: this.selectedAmenities
    }
    try {
      const response = await updateTripAmenities(this.tripId, payload)
      if (response && response.status === 200) {
        setTimeout(() => {
          this.$store.dispatch('app/showAlert', {
            type: 'success',
            message: 'Trip amenities successfully updated.',
          })
        }, 2000)
        EventBus.$emit('refresh-detail')
        this.$store.dispatch('app/closeDialog')
      }
    } catch (e) {
      this.$store.dispatch('app/showAlert', {
        type: 'error',
        message: 'There was an error updating the trip amenities.',
      })
    }
  }
}
